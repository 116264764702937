
/*-----------------------------------------------Self Defined CSS--------------------------------------------------*/

.tracking-tight {
  letter-spacing: -.025em;
}

.inline {
  display: inline;
}

.font-semibold {
  font-weight: 600;
}

.gradient-text {
  background: linear-gradient(90deg, #ff1cf7, #b249f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.gradient-text2 {
  background: linear-gradient(90deg, #7aaaea, #2575e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.gradient-text3 {
  background: linear-gradient(90deg, #9ceaa2, #28c13f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.gradient-text4 {
  background: linear-gradient(90deg, #F5B568,  #EF7863);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.gradient-text5 {
  background: linear-gradient(90deg, #EA7CB6, #E45D82);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.feature-section {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}